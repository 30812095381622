module.exports = {
    seo: {
        title : "שלבי עבודה במיזם חדש - Jenko",
        description:  ""
    },
    intro : {
        title :  "שלבי עבודה במיזם\u00A0חדש",
    },
    workFlowItems: [
        {   
            name: 'brain',
            title : "סיעור מוחות",
            desc : [
                "פגישה ראשונית בין בעל הרעיון\u00A0לחברה.",
                "בפגישה מפתחים את הרעיון מבחינה הנדסית, שיווקית\u00A0ופיננסית."
            ]
        },
        {   
            name: 'spec',
            title : 'אפיון ובדיקת התכנות',
            desc : [
                "תחילת עבודה, הלקוח מעביר דרישות לקוח (דגשים שלו למוצר), חברת הפיתוח מכינה דרישות הנדסיות ודרישות תכן (היתכנות הנדסית), סקר שוק על מוצרים דומים, אפיון לפי ממצאים, סקיצה של המוצר, רעיונות לשיווק (היתכנות שיווקית), הערכה ראשונית לעלות יחידה בייצור\u00A0המוני(היתכנות\u00A0פיננסית)."
            ]
        },
        {   
            name: 'prot',
            title : "ייצור אבטיפוס",
            desc : [
                "זהו השלב שמעבירים מתכנון לייצור.",
                "שלב מאתגר שדורש המון עבודה, משאבים, חומרי גלם ולכן הינו שלב יקר בתהליך מרעיון\u00A0למוצר.",
                "תוצאות האפיון הם אחד הפרמטרים החשובים האם להיכנס לפיתוח אבטיפוס\u00A0או\u00A0שלא.",
            ]           
        },
        {   
            name: 'patent',
            title : "רישום פטנט",
            desc : [
                "על מנת להגן על הרעיון והמיזם אנו נמליץ לבצע חיפוש ורישום (במידה ואפשר, לא\u00A0תמיד\u00A0ניתן).",
                'אנו עובדים בצמוד עם פירמת עורכי הדין "לוצאטו את לוצאטו" מה שנותן ליזם את הביטחון שכל תהליך חיפוש ורישום הפטנט מבוצע כהלכה ובסטנדרטים\u00A0הכי\u00A0גבוהים.',
            ]   
        },
        {   
            name: 'market',
            title : 'Marketing',
            desc : [
                 "השלב הזה הוא בעצם הכנה לגיוס המשקיעים.",
                 "ככל שהיזם ישקיע ויגיע עם יותר אינפורמציה למשקיעים כך סיכוי\u00A0הגיוס\u00A0יגדלו."
               ]
        },
        {   
            name: 'invest',
            title : 'גיוס משקיעים',
            desc : [
                "אנו עוזרים בחיבור לאנג'לים (משקיעים פרטיים), גיוס המונים, קרנות הון\u00A0סיכון\u00A0ומענקים."
            ]
        },
        {   
            name: 'prod',
            title : 'הכנה לייצור המוני',
            desc : [
                'הצעות מחיר ממפעלים, הקמת קווי ייצור, בדיקת איכות למוצר עד שמגיעים למוצר שלם ומוגמר, ייצור המוני, אריזה, הרכבה, משלוח לארץ\u00A0ולחו"ל.'
            ]
        },
    ],
    dir: "rtl"
}