import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../layouts/he";
import PageHead from "../../components/shared/PageHead";
import content from "../../data/content/wokrflow/workflow-he";
import WorkFlowList from "../../components/pages/WokrFlowList";
import * as styles from "../../styles/pages/workflow/index.module.css";
import Intro from "../../components/shared/Intro";

const WorkFlow = (props) => {
  const { sIntroSection, sIntro, sImgSec } = styles;
  const ImgSec = () => {
    return (
      <section className={sImgSec}>
        <StaticImage
          src="../../assets/images/workflow/office.jpeg"
          alt="Office"
          layout="fullWidth"
        />
      </section>
    );
  };
  return (
    <Layout location={props.location} lang="he">
      <PageHead pageHeadData={content.seo} />
      <article lang="he" className="workflow" dir="rtl">
        <Intro content={content.intro} styles={{ sIntroSection, sIntro }} />
        <ImgSec />
        <WorkFlowList content={content} />
      </article>
    </Layout>
  );
};

export default WorkFlow;
